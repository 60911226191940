// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-book-tsx": () => import("./../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-model-tsx": () => import("./../src/pages/model.tsx" /* webpackChunkName: "component---src-pages-model-tsx" */),
  "component---src-pages-models-tsx": () => import("./../src/pages/models.tsx" /* webpackChunkName: "component---src-pages-models-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

